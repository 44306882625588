import $ from 'jquery';
global.jQuery = $;
global.$ = $;

import '../lib/bootstrap/dist/js/bootstrap';

import 'jquery-ui';
import 'webpack-jquery-ui';

import 'bootstrap-fileinput';
import 'bootstrap-fileinput/js/plugins/piexif';
// import 'bootstrap-fileinput/js/plugins/purify';
import 'bootstrap-fileinput/js/plugins/sortable';
import 'bootstrap-fileinput/js/locales/ru';
import 'bootstrap-fileinput/css/fileinput.min.css';


// dx ->
import '../css/devextreme/dx.common.css';
import '../css/devextreme/dx.light.css';

import '@wwwroot/css/style.css';
import '@wwwroot/css/list.css';
import '@wwwroot/css/dialog.css';


import './site';
import './OtherFuncs';
import './dialog';